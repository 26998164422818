<template>
	<div>
		<b-container
			class="d-flex align-items-center justify-content-center vh-100"
		>
			<b-overlay blur="5px" variant="transparent" :show="loading">
				<b-row v-if="transaction" align-h="center" align-v="center">
					<b-col cols="12" sm="10" md="7">
						<h1 class="font-weight-normal">Good Day</h1>
						<br />
						<p style="font-size: 18px">
							Please click the button below to confirm that you have delivered
							<span class="text-success">{{
								formatMoney(transaction.additionalFloat)
							}}</span>
							to the branch manager at {{ transaction.branch.name }}.
						</p>
						<br />
						<b-button @click="completeReinjection" variant="success"
							>Confirm Delivery</b-button
						>
					</b-col>
				</b-row>
			</b-overlay>
		</b-container>
		<b-modal
			centered
			no-close-on-esc
			hide-header-close
			no-close-on-backdrop
			id="confirm-complete"
			title="Transaction Completed"
			hide-footer
		>
			<p>Thank you for confirming this transaction.</p>
			<p>Feel free to close this page</p>
		</b-modal>
		<b-modal
			centered
			no-close-on-esc
			hide-header-close
			no-close-on-backdrop
			id="already-complete"
			title="Transaction Already Completed"
			hide-footer
		>
			<p>
				It would seem as though this transaction has alrady been completed. You
				may contact the admin for more information.
			</p>
			<p>Feel free to close this page</p>
		</b-modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				transaction: null,
				alreadyCompleted: false,
			};
		},
		page: {
			title: "Complete Float Reinjection",
		},
		created() {
			this.getFloatReinjection();
		},
		methods: {
			async getFloatReinjection() {
				try {
					const { data } = await this.$store.dispatch(
						"transactionsModule/getSingleTransaction",
						this.$route.params.id
					);
					if (data.completed == true) {
						this.$bvModal.show("already-complete");
						return (this.alreadyCompleted = true);
					}
					this.transaction = data;
				} catch (error) {
					console.log(error);
				}
			},
			async completeReinjection() {
				try {
					this.loading = true;
					const { data } = await this.$store.dispatch(
						"transactionsModule/markComplete",
						this.$route.params.id
					);
					this.loading = false;
					this.$bvModal.show("confirm-complete");
				} catch (error) {
					console.log(error);
				}
			},
			formatMoney(money) {
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(money);
			},
		},
	};
</script>

<style></style>
